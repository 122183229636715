import {useContext, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import parse from 'html-react-parser'
import Button from './Button'
import poweredByContext from '../store/PoweredByContext'
import getClassName from '../utilities/getClassName'
import {isInternalUrl} from '../utilities/url'
import {replaceShyName} from '../utilities/string'
import createHeading from '../utilities/createHeading'

const BlockText = ({ buttonOverride, textBlocks, ctaUrl, ctaText, mainHeading, subHeading }) => {

    // State
    const [showMore, setShowMore] = useState([])

    // Context
    const poweredByCtx = useContext(poweredByContext)

    // Ref
    const containerRef = useRef([])
    const contentRef = useRef([])

    /*
    * Replace Shy Aviation with the tenant name, if necessary
    */
    const replaceName = (str) => {
        if (str) {
            return replaceShyName(str, poweredByCtx.isPoweredBy, poweredByCtx?.tenant?.tenantName)
        }
    }

    /*
    * Show the full text
    */
    const showText = (i) => {
        let _showMore = JSON.parse(JSON.stringify(showMore))

        _showMore[i] = true

        setShowMore(_showMore)
    }

    /*
    * When the component loads, determine how many sections should have/show the "show more" functionality
    */
    useEffect(() => {
        let _showMore = []

        textBlocks.forEach((section, i) => {
            _showMore.push(false)
        })

        setShowMore(_showMore)
        
        textBlocks.forEach((section, i) => {
            if (containerRef.current[i].clientHeight >= contentRef.current[i].clientHeight) {
                if (!window.matchMedia('(min-width: 765px)').matches) {
                    showText(i)
                }
            }
        })
    }, [])

    return (
        <section className='block-text'>
            <div className='wrap'>
                {textBlocks.map((section, i) => {
                    return (
                        <div className='block-text__item' key={i}>
                            <header className='block-header block-header--light'>
                                {createHeading(section.props.subHeading, replaceName(section.props.subTitle), true)}
                                {createHeading(section.props.mainHeading, replaceName(section.props.title), false)}
                            </header>
                            {section.props.text &&
                                <div className={getClassName(['block-text__text', 'block-read-more', showMore[i] ? 'block-read-more--active' : undefined, poweredByCtx?.tenant?.isLandingPageBlackText ? 'block-text__text--dark' : undefined])}>
                                    <div
                                        className='block-read-more__container'
                                        ref={ref => {
                                            containerRef.current[i] = ref
                                        }}
                                    >
                                        <div
                                            className='block-read-more__content'
                                            ref={ref => {
                                                contentRef.current[i] = ref
                                            }}
                                        >
                                            {parse(replaceName(section.props.text))}
                                        </div>
                                    </div>
                                    <button className='block-read-more__button' onClick={() => {
                                        showText(i)
                                    }}>{replaceName(section.props.showMoreText) || 'Show more'}</button>
                                </div>
                            }
                        </div>
                    )
                })}
                {ctaUrl && ctaText &&
                    <div className='block-text__footer'>
                        {buttonOverride
                            ?
                                <Button
                                    classNames={['button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary']}
                                    onClick={buttonOverride}
                                >
                                    {replaceName(ctaText)}
                                </Button>
                            :
                                <>
                                    {isInternalUrl(ctaUrl)
                                        ?
                                            <Link to={ctaUrl} className={getClassName(['button', 'button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary'])}>
                                                <span>{replaceName(ctaText)}</span>
                                            </Link>
                                        :
                                            <a href={ctaUrl} className={getClassName(['button', 'button--full', poweredByCtx.isPoweredBy ? undefined : 'button--tertiary'])}>
                                                <span>{replaceName(ctaText)}</span>
                                            </a>
                                    }
                                </>
                        }
                    </div>
                }
            </div>
        </section>
    )
}

export default BlockText