import FromToTable from '../components/FromToTable'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import useGet from '../hooks/useGet'
import LoadingOverlay from '../components/LoadingOverlay'
import ErrorModal from '../components/ErrorModal'
import { NumericFormat } from 'react-number-format'
import poweredByContext from '../store/PoweredByContext'
import { useGetCacheContent } from '../hooks/useGetCacheContent'
import format from "date-fns/format"
import parse from "date-fns/parse"
import {getMeasurements} from '../utilities/string'
import {isFloatingFleet} from '../utilities/aircraft'

// Icons
import shyLogo from '../assets/icons/shy-logo-quote-pdf.svg'
import shyLogoDark from '../assets/icons/logo-dark.svg'
import cateringIcon from '../assets/icons/catering-alt-dark.svg'
import wifiIcon from '../assets/icons/wifi-dark.svg'
import wcIcon from '../assets/icons/toilet-dark.svg'
import luggageIcon from '../assets/icons/luggage-dark.svg'
import smokingIcon from '../assets/icons/smoking-dark.svg'
import attendantsIcon from '../assets/icons/cabin-crew-dark.svg'
import petsIcon from '../assets/icons/pets-dark.svg'
import subjectToWeightIcon from '../assets/icons/subject-to-weight-dark.svg'
import subjectToConditionsIcon from '../assets/icons/subject-to-conditions-dark.svg'
import fuelStopIcon from '../assets/icons/fuel-stop-dark.svg'
import ownerApprovalIcon from '../assets/icons/owner-approval-dark.svg'

const QuotePdf = () => {

    // State
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [aircraftCount, setAircraftCount] = useState(0)
    const [legCount, setLegCount] = useState(0)

    // Hooks
    const poweredByCtx = useContext(poweredByContext)

    // Get and cache content
    const {content: homeContent} = useGetCacheContent('homeContent', '/api/content/home')

    // Get legId and name from url
    const {search} = useLocation()
    const queryParams = new URLSearchParams(search)
    const legId = queryParams.get('lo')
    const name = queryParams.get('name')

    // Get quote data
    const {error: aircraftOptionsError, returnData: aircraftOptions} = useGet(`/api/frontend/v2/get-search-results?LegId=${legId}`)
    const {error: tripInfoError, returnData: tripInfo} = useGet(`/api/frontend/get-search-results-header?LegId=${legId}`)

    // Wait for all data to load and check for errors
    useEffect(() => {
        if ( aircraftOptionsError || tripInfoError) {
            setError(true)
        } else {
            if (aircraftOptions && tripInfo) {
                setLoading(false);
                setLegCount(tripInfo?.legs?.length)
                setAircraftCount(aircraftOptions?.length)
            }
        }
    }, [aircraftOptionsError, tripInfoError, aircraftOptions, tripInfo])

    // Add PDF view class to body
    useEffect(() => {
        document.body.classList.add('pdf-view', 'pdf-view--hide-header')

        // Clean up PDF view
        return(() => {
            document.body.classList.remove('pdf-view', 'pdf-view--hide-header')
        })
    })

    // Get home content
    const getPhoneNumber = () => {       
        if (poweredByCtx && poweredByCtx.isPoweredBy && poweredByCtx?.tenant.tenantPhoneNumber) {
            return poweredByCtx.tenant.tenantPhoneNumber.split(';').map(number => number.trim());
        }
        return [homeContent?.getInTouch?.phoneNumber];
    };
    

    const getEmailAddress = () => {
        if (poweredByCtx.isPoweredBy && poweredByCtx?.tenant.tenantEmailAddress) {
            return poweredByCtx.tenant.tenantEmailAddress
        }
        return homeContent.getInTouch.emailAddress
    }

    // Get logo
    const getLogo = (recommended) => {
        let image;

        // Check if the context is powered by a tenant
        if (poweredByCtx.isPoweredBy) {
            try {
                // Fetch the logo image from the CMS
                image = poweredByCtx?.tenant?.logoImage;
                if (image) {
                    return image;
                } else {
                    console.log(`Logo image not available for ${poweredByCtx.tenantID}`);
                }
            } catch (e) {
                console.log(`Error fetching logo for ${poweredByCtx.tenantID}: ${e.message}`);
            }
        }

        // Return default logos if not powered by a tenant or if an error occurred
        return recommended ? shyLogoDark : shyLogo;
    }

    // Add PBS override class
    const pbsOverride = poweredByCtx.isPoweredBy

    const getMovidaPoints = (price) => {
        return Math.floor(price.gbp / 100 * 1)
    }

    return (
        <div className='quote-pdf'>
            {error && <ErrorModal errorMessage='PDF Could not be created' />}
            {loading && <LoadingOverlay clear />}
            {!loading && tripInfo && aircraftOptions && (
                <>
                    {aircraftOptions.map((aircraft, index) => {

                        // Format date
                        const simplifiedDate = tripInfo.quotesreleaseDate.substring(0, 10)
                        const parseDate = parse(simplifiedDate, "yyyy-MM-dd", new Date())
                        const formattedDate = format(parseDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })

                        // Set recommended header class
                        let recommendedClass = ''
                        if (aircraft.recommended) {
                            recommendedClass = 'quote-pdf__header--recommended'
                        }

                        // Set dimensions format when there's no crossection image
                        let dimensionsClass = ''
                        if (!aircraft.floorPlan[2]) {
                            dimensionsClass = 'quote-pdf__dimensions--no-cross-section'
                        }

                        // Set smoking option
                        let smoking = aircraft.smokingOption
                        if (aircraft.smokingOption === 'N') {
                            smoking = 'No'
                        } else if (aircraft.smokingOption === 'Y') {
                            smoking = 'Yes'
                        }

                        return (
                            <div key={aircraft.id} className='quote-pdf__page'>
                                <header className={`quote-pdf__header ${pbsOverride && 'quote-pdf__header--pbs'} ${recommendedClass}`}>
                                    <img className="quote-pdf__logo" alt='' src={getLogo(aircraft.recommended)} />
                                    <div className="quote-pdf__header-recommends">
                                        {pbsOverride ? 'Recommended' : 'SHY Recommends'}
                                    </div>
                                </header>

                                <table className='quote-pdf__header-table'>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <span>Quote {index + 1} <span
                                                className='quote-pdf__lowercase'>of</span> {aircraftCount}</span>
                                        </td>
                                        <td>
                                            <span>Agent: </span>
                                            <span>{tripInfo.agentName}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {name && <span>{name}</span>}
                                            {tripInfo.companyName && <span> - {tripInfo.companyName}</span>}
                                        </td>
                                        <td>
                                            <span>Phone:</span>
                                            <span>
                                               {tripInfo.agentPhone ?
                                                   <a href={`tel:${tripInfo.agentPhone}`}>{tripInfo.agentPhone}</a>
                                                   :
                                                   <>-</>
                                            }
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>Reference:</span>
                                            <span>{tripInfo.tripName}</span>
                                        </td>
                                        <td>
                                            <span>Team:</span>
                                            <span>
                                                {getPhoneNumber().map((phoneNumber, i) => {
                                                    return (
                                                        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                                                    )
                                                })}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>Date:</span>
                                            <span>{formattedDate}</span>
                                        </td>
                                        <td>
                                            <span>Email:</span>
                                            <span>
                                                <a href={`mailto:${getEmailAddress()}`}>{getEmailAddress()}</a>
                                            </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <div className='quote-pdf__aircraft-images'>
                                    {aircraft.photoData[0] &&
                                        <img
                                            src={aircraft.photoData[0].url}
                                            alt={aircraft.photoData[0].alt}
                                        />
                                    }
                                    {aircraft.photoData[1] &&
                                        <img
                                            src={aircraft.photoData[1].url}
                                            alt={aircraft.photoData[1].alt}
                                        />
                                    }
                                </div>

                                <FromToTable
                                    departureDateTime={aircraft.departureDateTime}
                                    arrivalDateTime={aircraft.arrivalDateTime}
                                    legData={tripInfo.legs}
                                    duration={aircraft.duration}
                                    fuelStops={aircraft.fuelStops}
                                />

                                <div className='quote-pdf__aircraft-details'>

                                    <div className='quote-pdf__aircraft-details-column'>
                                        <h2>Aircraft</h2>
                                        <table className='quote-pdf__details-table'>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <span>{aircraft.aircraftName},</span>
                                                    <span>{aircraft.category}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Manufactured:</span>
                                                    <span>{aircraft.yom}{aircraft.refurbishedYear !== '' ? ` (Refurb ${aircraft.refurbishedYear})` : ``}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Seating capacity:</span>
                                                    <span>Up to {aircraft.seats} passengers</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <div className={`quote-pdf__dimensions ${dimensionsClass}`}>

                                            {aircraft.floorPlan[0] &&
                                                <img className="quote-pdf__dimensions-layout-image"
                                                    src={aircraft.floorPlan[0].url}
                                                    alt={aircraft.floorPlan[0].alt}
                                                />
                                            }

                                            <div className="quote-pdf__dimensions-length">
                                                <span>Length:</span>
                                                <span>{getMeasurements(aircraft.cabinLength)}</span>
                                            </div>

                                            <div className="quote-pdf__dimensions-width">
                                                <span>Width:</span>
                                                <span>{getMeasurements(aircraft.cabinWidth)}</span>
                                            </div>

                                            <div className="quote-pdf__dimensions-height">
                                                <span>Height:</span>
                                                <span>{getMeasurements(aircraft.headRoom)}</span>
                                            </div>

                                            {aircraft.floorPlan[2] &&
                                                <img className="quote-pdf__dimensions-cross-section-image"
                                                     src={aircraft.floorPlan[2].url}
                                                     alt={aircraft.floorPlan[2].alt}
                                                />
                                            }

                                        </div>
                                    </div>

                                    <div className="quote-pdf__aircraft-details-dividing-line"></div>

                                    <div>
                                        <div className="quote-pdf__aircraft-details-column">
                                            <h2>Amenities</h2>

                                            <table className='quote-pdf__details-table'>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <img src={cateringIcon} alt=''/>
                                                        <span>Catering</span>
                                                        <span>- {aircraft.cateringOption}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={wifiIcon} alt=''/>
                                                        <span>WiFi</span>
                                                        <span>- {aircraft.wifi ? 'Yes' : 'No'}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={wcIcon} alt=''/>
                                                        <span>Enclosed WC</span>
                                                        <span>- {aircraft.toilet ? 'Yes' : 'No'}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={luggageIcon} alt=''/>
                                                        <span>Luggage</span>
                                                        <span>- {aircraft.luggage} items 20kg max</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={smokingIcon} alt=''/>
                                                        <span>Smoking</span>
                                                        <span>- {smoking}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={attendantsIcon} alt=''/>
                                                        <span>Flight Attendants</span>
                                                        <span>- {(aircraft.flightAttendants === 'Y') ? 'Yes' : 'No'}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src={petsIcon} alt=''/>
                                                        <span>Pets</span>
                                                        <span>- {aircraft.pets}</span>
                                                    </td>
                                                </tr>
                                                {aircraft.subjectToWeight === 'Y' &&
                                                    <tr>
                                                        <td>
                                                            <img src={subjectToWeightIcon} alt=''/>
                                                            <span>Subject to weight</span>
                                                            <span>- {aircraft.subjectToWeight === 'Y' ? 'Yes' : 'No'}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {aircraft.subjectToConditions !== 'None' &&
                                                    <tr>
                                                        <td>
                                                            <img src={subjectToConditionsIcon} alt=''/>
                                                            <span>Subject to conditions</span>
                                                            <span>- {aircraft.subjectToConditions}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {aircraft.fuelStop > 0 &&
                                                    <tr>
                                                        <td>
                                                            <img src={fuelStopIcon} alt=''/>
                                                            <span>Fuel stop</span>
                                                            <span>- {aircraft.fuelStop}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                {aircraft.ownerApproval === 'Y' &&
                                                    <tr>
                                                        <td>
                                                            <img src={ownerApprovalIcon} alt=''/>
                                                            <span>Owner approval</span>
                                                            <span>- {aircraft.ownerApproval === 'Y' ? 'Yes' : 'No'}</span>
                                                        </td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </table>

                                            <h2>Notes</h2>
                                            {aircraft.notes &&
                                                <p>{aircraft.notes}</p>
                                            }
                                            <p><em>All times shown in local time. Aircraft are subject to continued availability of aircraft and crew, slots and permits.</em></p>
                                            {isFloatingFleet(aircraft) &&
                                                <p><strong>"Floating Fleet" - <em>Floating fleet is a supplier with multiple of the same OR similar category of aircraft (Interior and exterior aesthetics are subject to change)</em></strong></p>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={`quote-pdf__footer ${pbsOverride && 'quote-pdf__footer--pbs'}`}>
                                    <div className='quote-pdf__footer-column'>

                                        {tripInfo.showMovida &&
                                            <div className='quote-pdf__movida-info'>
                                                <div className='quote-pdf__movida-info-title'>Movida Points</div>
                                                <div className='quote-pdf__movida-info-points'>{getMovidaPoints(aircraft.price)}</div>
                                                <div className='quote-pdf__movida-info-text'>This booking will earn you {getMovidaPoints(aircraft.price)} Movida points
                                                </div>
                                            </div>
                                        }

                                        <div className='quote-pdf__legal-info'>
                                            Price quoted is subject to change and may incur additional costs. For more
                                            information, please refer to our <a href={tripInfo.showMovida ? '/movida-terms-conditions' : '/terms-conditions'}>Terms and Conditions</a>.
                                        </div>
                                    </div>

                                    <div className='quote-pdf__footer-column'>

                                        <div className='quote-pdf__price'>
                                            <span className='quote-pdf__price-from'>Price:</span>

                                            <span className='quote-pdf__price-amount'>
                                                {aircraft.preferredCurrency === "GBP" && <>£</>}
                                                {aircraft.preferredCurrency === "USD" && <>$</>}
                                                {aircraft.preferredCurrency === "EUR" && <>€</>}
                                                <NumericFormat
                                                    displayType='text'
                                                    value={aircraft.price[aircraft.preferredCurrency.toLowerCase()]}
                                                    thousandSeparator
                                                    decimalScale={0}
                                                />
                                            </span>
                                        </div>
                                        <a href={`/book?lo=${legId}&arfq=${aircraft.id}&tailNum=${aircraft.tailNumber}&name=${name}`} className='quote-pdf__book-now-cta'>Book Now</a>
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </>
            )}
        </div>
    )
}
export default QuotePdf