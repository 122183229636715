import {getUrl} from './url'

export const isPelorus = (poweredByCtx) => {
	return poweredByCtx?.tenantID === 'PLRS'
}

export const isManchesterUnited = (poweredByCtx) => {
	return poweredByCtx?.tenantID?.toLowerCase() === 'pbs-mu'
}

const getSchemaSocialLinks = (poweredByCtx) => {
	let options = ['instagram', 'facebook', 'twitter', 'tiktok', 'linkedin']

	const links = options.map((option) => {
		return poweredByCtx?.tenant[option]
	})

	return links.filter((link) => {
		return link !== undefined
	})
}

export const getSchema = (poweredByCtx) => {
	const context = 'https://schema.org'
	const type = 'Organization'
	const id = getUrl(poweredByCtx, '/#organisation')
	const url = getUrl(poweredByCtx, '/')
	const description = 'We are a global aviation charter company, with access to every available aircraft on the market.'

	if (poweredByCtx.isPoweredBy) {
		return {
			'@context': context,
			'@type': type,
			'@id': id,
			'name': poweredByCtx?.tenant?.tenantName,
			'url': url,
			'logo': poweredByCtx?.tenant?.appleTouchIcon,
			'description': description,
			'sameAs': getSchemaSocialLinks(poweredByCtx)
		}
	} else {
		return {
			'@context': context,
			'@type': type,
			'@id': id,
			'name': 'Shy Aviation',
			'url': url,
			'address': {
				'@type': 'PostalAddress',
				'addressLocality': 'London',
				'addressCountry': 'United Kingdom',
				'postalCode': 'WC1V 7DN',
				'streetAddress': '233 High Holborn'
			},
			'logo': '/icons/shy-logo.png',
			'description': description,
			'sameAs': [
				'https://www.instagram.com/shyaviation_official',
				'https://www.facebook.com/shyaviation/',
				'https://twitter.com/shyaviation',
				'https://www.tiktok.com/@shyaviation',
				'https://www.linkedin.com/company/shy-aviation'
			]
		}
	}
}